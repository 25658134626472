import {createRouter, createWebHashHistory} from 'vue-router'
import ChatFunction from "@/views/chat/ChatFunction.vue";


const routes = [
    {
        path: '/',
        name: 'chat-function',
        component: ChatFunction,
        meta: {title: 'CHAT', keepAlive: true}
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})


// TODO 全局前置守卫
router.beforeEach(async (to) => {
    let {title} = to.meta
    // TODO 设置浏览器Title
    document.title = (title ? title : '') + '- TS GPT'
})

export default router
