<template>
  <!--菜单栏-->
  <menu-component :menu-list="menuList" />
  <!--视图渲染-->
  <render-component />
</template>


<script setup>
import { onMounted, ref } from "vue";
import MenuComponent from "@/components/navigationBar/components/MenuComponent.vue";
import RenderComponent from "@/components/navigationBar/components/RenderComponent.vue";
// import store from "@/store";

const menuList = ref([
  {
    iconDefault: "chat.svg",
    iconActive: "active/chat-active.svg",
    to: "/",
  },
]);

onMounted(() => {});
</script>

<style scoped>
</style>
